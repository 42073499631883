body {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  background-image: url("./images/bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.css-1vw5i9y-MuiButtonBase-root-MuiTab-root{
  font-family: Poppins !important;
}

.navbar-link {
  text-decoration: none;
  color: white;
}

.service-card {
  border: 2px solid #dedede;
  border-radius: 17px;
  width: 330px;
}

.service-card-label {
  font-size: 18px;
  color: #3e3e3e;
}

.service-card-content {
  color: #1570EF;
  font-size: 19px !important;
  overflow-wrap: break-word !important;
}

.service-card-button {
  padding: 1rem !important;
  height: 45px !important;
  background: #039855 !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  color: white !important;
  font-family: 'Roboto' !important;
}

.service-card-button-update {
  padding: 1rem !important;
  height: 45px !important;
  background: #d1a507 !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  color: white !important;
  font-family: 'Roboto' !important;
}

.service-card-button-print {
  padding: 1rem !important;
  height: 45px !important;
  background: teal !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  color: white !important;
  font-family: 'Roboto' !important;
}

.FormInput {
  width: 100%;
  background: #FEFCFD;
  border-radius: 10px !important;
  font-size: 14px !important;
  font-family: montserrat, arial, verdana !important;
}

.MultilineInput {
  width: 100%;
  background: #FEFCFD;
  border-radius: 10px !important;
  font-size: 14px !important;
  font-family: montserrat, arial, verdana !important;
}

.serviceadd-button {
  background-color: #12b40c !important;
  color: #fff !important;
  margin-top: 3rem !important;
  height: 50px;
  width: 30%;
}

.serviceadd-button-update {
  background-color: #d1a507 !important;
  color: #fff !important;
  text-decoration: none;
  font-size: large;
  margin-left: 1rem !important;
  height: 45px !important;
  width: 50%;
}

.archive-table {
  overflow-wrap: break-word !important;
}

.receipt-right-side {
  padding: 6px;
  text-align: right;
  font-size: 12px;
}

.receipt-left-side {
  padding: 6px;
  text-align: left;
  font-size: 12px;

}

.receipt-print-button {
  padding: 1rem !important;
  height: 45px !important;
  background: teal !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  color: white !important;
  font-family: 'Roboto' !important;
  margin-top: 1.5rem !important;
}

.receipt-table {
  width: 100%;
}

.login-card {
  width: 330px;
  height: 380px;
  border: #c0c0c0 2px solid !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
}

.login-stack {
  justify-content: center !important;
  align-items: center !important;
}

.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(-225deg,
      #452b2b 0%,
      #39445a 29%,
      #8c977b 67%,
      #EABE4C 100%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 190px;
}

/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

/*=============== VARIABLES CSS ===============*/
:root {
  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --first-color: hsl(244, 75%, 57%);
  --second-color: hsl(249, 64%, 47%);
  --title-color: hsl(244, 12%, 12%);
  --text-color: hsl(244, 4%, 36%);
  /*========== Font and typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --body-font: "Poppins", sans-serif;
  --h2-font-size: 1.25rem;
  --small-font-size: .813rem;
  --smaller-font-size: .75rem;
  /*========== Font weight ==========*/
  --font-medium: 500;
  --font-semi-bold: 600;
}

@media screen and (min-width: 1024px) {
  :root {
    --h2-font-size: 1.75rem;
    --normal-font-size: 1rem;
    --small-font-size: .875rem;
    --smaller-font-size: .813rem;
  }
}

/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: var(--body-font);
  color: var(--text-color);
}

input,
button {
  font-family: var(--body-font);
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

/*=============== LOGIN FORM ===============*/
.login__content,
.login__form,
.login__inputs {
  display: grid;
}

.login__content {
  position: relative;
  height: 100vh;
  align-items: center;
}

.login__img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.login__form {
  position: relative;
  background-color: hsla(244, 16%, 92%, 0.6);
  border: 2px solid hsla(244, 16%, 92%, 0.75);
  margin-inline: 1.5rem;
  row-gap: 1.25rem;
  backdrop-filter: blur(20px);
  padding: 2rem;
  border-radius: 1rem;
}

.login__title {
  color: var(--title-color);
  font-size: var(--h2-font-size);
  margin-bottom: 0.5rem;
}

.login__title span {
  color: var(--first-color);
}

.login__description {
  font-size: var(--small-font-size);
}

.login__inputs {
  row-gap: 0.75rem;
  margin-bottom: 0.5rem;
}

.login__label {
  display: block;
  color: var(--title-color);
  font-size: var(--small-font-size);
  font-weight: var(--font-semi-bold);
  margin-bottom: 0.25rem;
}

.login__input {
  width: 100%;
  padding: 14px 12px;
  border-radius: 6px;
  border: 2px solid var(--text-color);
  background-color: hsla(244, 16%, 92%, 0.6);
  color: var(--title-color);
  font-size: var(--smaller-font-size);
  font-weight: var(--font-medium);
  transition: border 0.4s;
}

.login__input::placeholder {
  color: var(--text-color);
}

.login__input:focus,
.login__input:valid {
  border: 2px solid var(--first-color);
}

.login__box {
  position: relative;
}

.login__box .login__input {
  padding-right: 36px;
}

.login__eye {
  width: max-content;
  height: max-content;
  position: absolute;
  right: 0.75rem;
  top: 0;
  bottom: 0;
  margin: auto 0;
  font-size: 1.25rem;
  cursor: pointer;
}

.login__check {
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
}

.login__check-input {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid var(--text-color);
  background-color: hsla(244, 16%, 92%, 0.2);
  border-radius: 0.25rem;
}

.login__check-input:checked {
  background: var(--first-color);
}

.login__check-input:checked::before {
  content: "✔";
  display: block;
  color: #fff;
  font-size: 0.75rem;
  transform: translate(1.5px, -2.5px);
}

.login__check-label {
  font-size: var(--small-font-size);
}

.login__buttons {
  display: flex;
  column-gap: 0.75rem;
}

.login__button {
  width: 100%;
  padding: 14px 2rem;
  border-radius: 6px;
  background: linear-gradient(180deg, var(--first-color), var(--second-color));
  color: #fff;
  font-size: var(--small-font-size);
  font-weight: var(--font-semi-bold);
  box-shadow: 0 6px 24px hsla(244, 75%, 48%, 0.5);
  margin-bottom: 1rem;
  cursor: pointer;
}

.login__button-ghost {
  background: hsla(244, 16%, 92%, 0.6);
  border: 2px solid var(--first-color);
  color: var(--first-color);
  box-shadow: none;
}

.login__forgot {
  font-size: var(--smaller-font-size);
  font-weight: var(--font-semi-bold);
  color: var(--first-color);
  text-decoration: none;
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 360px) {
  .login__buttons {
    flex-direction: column;
  }
}

/* For medium devices */
@media screen and (min-width: 576px) {
  .login__form {
    width: 450px;
    justify-self: center;
  }
}

/* For large devices */
@media screen and (min-width: 1064px) {
  .container {
    height: 100vh;
    display: grid;
    place-items: center;
  }

  .login__content {
    width: 1024px;
    height: 700px;
  }

  .login__img {
    border-radius: 3.5rem;
    box-shadow: 0 24px 48px hsla(240, 2%, 62%, 0.45);
  }

  .login__form {
    justify-self: flex-end;
    margin-right: 4.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .login__content {
    height: 700px;
  }

  .login__form {
    row-gap: 2rem;
    padding: 3rem;
    border-radius: 1.25rem;
    border: 2.5px solid hsla(244, 16%, 92%, 0.75);
  }

  .login__description,
  .login__label,
  .login__button {
    font-size: var(--normal-font-size);
  }

  .login__inputs {
    row-gap: 1.25rem;
    margin-bottom: 0.75rem;
  }

  .login__input {
    border: 2.5px solid var(--text-color);
    padding: 1rem;
    font-size: var(--small-font-size);
  }

  .login__input:focus,
  .login__input:valid {
    border: 2.5px solid var(--first-color);
  }

  .login__button {
    padding-block: 1rem;
    margin-bottom: 1.25rem;
  }

  .login__button-ghost {
    border: 2.5px solid var(--first-color);
  }
}

@media screen and (max-width: 1064px) {
  .login__img {
    display: none;
  }
}

.archive-box {
  float: left !important;
}

.archived-button {
  color: #fff !important;
  background-color: #d1a507 !important;
}

/* @media (max-width :600px) {
  .print-button {
    display: none !important;
  }
} */

.service-card-phone-number {
  text-decoration: none;
}